<template>
  <section
    v-if="hasContent"
    id="carousel"
    class="Organization--Carousel"
  >
    <div class="Container">
      <div class="SectionHeader">
        <span class="SectionHeader--Icon">
          <HaIcon :icon="faImage" />
        </span>
        <h2>
          <span>{{ $t('organization.carousel.title_01') }}</span>
          {{ $t('organization.carousel.title_02') }}
        </h2>
      </div>
      <template v-if="editMode">
        <ul
          :class="[
            'MediaList',
            { 'MediaList-WithContent': hasMedia }
          ]"
        >
          <Media
            v-for="(video, index) in videos"
            :key="video.url"
            :index="index + 1"
            :media="video"
            is-video
          />
          <Media
            v-for="(image, index) in images"
            :key="image.id"
            :index="index + 1"
            :media="image"
          />
          <add-image @show-modal="displayModalUpload = true" />
          <add-video />
        </ul>
      </template>
      <template v-else>
        <div class="Carousel">
          <Swiper
            :navigation="true"
            :modules="[SwiperNavigation]"
            :loop="true"
            :grab-cursor="true"
          >
            <SwiperSlide
              v-for="video in videos"
              :key="video.id"
            >
              <ClientOnly>
                <video-embed
                  class="Carousel--Video"
                  :src="video.url"
                />
              </ClientOnly>
            </SwiperSlide>
            <SwiperSlide
              v-for="(image, i) in images"
              :key="image.id"
              :index="i"
            >
              <NuxtImg
                format="webp"
                quality="80"
                width="600"
                height="300"
                :src="image.url"
                :alt="image.caption"
              />
              <span
                v-if="image.caption"
                class="Carousel--Caption"
              >
                {{ image.caption }}
              </span>
            </SwiperSlide>
          </Swiper>
        </div>
      </template>
    </div>
    <ModalUpload
      v-if="displayModalUpload"
      :ratio="2"
      :max-width="600"
      :max-height="300"
      :action="'addImage'"
      file-name="media"
      @close="displayModalUpload = false"
      @image="newImage = $event"
    />
  </section>
</template>

<script setup>
import { faImage } from '@fortawesome/pro-solid-svg-icons'
import { HaIcon } from '#components'
import { resizedSrc } from '@/helpers/utils'
import ModalUpload from '@/components/Modal/ModalUpload.vue'
import Media from '@/components/Organization/Partials/Media.vue'
import AddImage from '@/components/Organization/Partials/AddImage.vue'
import AddVideo from '@/components/Organization/Partials/AddVideo.vue'
import VideoEmbed from '@/components/VideoEmbed/VideoEmbed.vue'
import useEditMode from '@/composables/useEditMode'
import { ref, computed, SwiperNavigation } from '#imports'
import { useOrganizationStore } from '@/store/organization.store'

const { editMode } = useEditMode()
const newImage = ref(null)
const displayModalUpload = ref(false)
const organizationStore = useOrganizationStore()

const videos = computed(() => organizationStore.organization?.videos)
const images = computed(
  () => organizationStore.organization?.galleryImages
)
const hasMedia = computed(
  () => videos.value?.length || images.value?.length
)
const hasContent = computed(
  () =>
    organizationStore.organization?.galleryImages?.length ||
    organizationStore.organization?.videos?.length ||
    editMode.value
)
</script>

<style lang="scss">
@import './styles/carousel';
</style>

<style lang="scss" scoped>
#carousel {
  --swiper-theme-color: var(--hads-color-flint);
  --swiper-navigation-size: 2rem;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  user-select: none;

  .Carousel--Video {
    width: 600px;
    height: 300px;
  }

  img {
    object-fit: contain;
  }
}
</style>
