<template>
  <section
    v-if="!editMode"
    id="share"
    class="Organization--Share"
  >
    <div class="Container">
      <div class="ShareWrapper">
        <div class="Heading">
          <h3>{{ $t('organization.share.title') }}</h3>
          <p>{{ $t('organization.share.subtitle') }}</p>
        </div>
        <HaDropdown alignment="right">
          <template #trigger>
            <HaButton
              class="Cta"
              data-ux="Explore_OrganizationPublicPage_Share_Toggle_ShareLinks"
            >
              {{ $t('organization.share.cta') }}
            </HaButton>
          </template>
          <template #content>
            <ha-menu-list>
              <ha-menu-list-item
                v-for="item in items"
                :key="item.id"
                :class="`HaMenuListItem-${
                  item.name.charAt(0).toUpperCase() +
                  item.name.slice(1)
                }`"
                :icon="item.icon"
                :label="item.label"
                :data-ux="item.dataUx"
                @click="openSharePopup(item.href)"
              />
              <ha-menu-list-item
                v-if="isNativeShareAvailable"
                class="HaMenuListItem-genericShare"
                :icon="faArrowUpRightFromSquare"
                :label="
                  $t('organization.share.networks.genericShare.label')
                "
                data-ux="Explore_OrganizationPublicPage_Share_GenericShare"
                @click="openSharePopup(null, true)"
              />
              <ha-menu-list-item
                class="HaMenuListItem-Email"
                :icon="faEnvelope"
                :label="$t('organization.share.networks.email.label')"
                :href="`mailto:?to=&subject=${$t(
                  'organization.share.networks.email.subject',
                  [organization.name]
                )}&body=${$t(
                  'organization.share.networks.email.body',
                  [organizationUrl]
                )}`"
                data-ux="Explore_OrganizationPublicPage_Share_ShareByEmail"
                rel="nofollow"
              />
              <ha-menu-list-item
                class="HaMenuListItem-CopyUrl"
                :icon="faLink"
                :label="
                  $t('organization.share.networks.copyUrl.label')
                "
                data-ux="Explore_OrganizationPublicPage_Share_CopyUrl"
                @click="copyUrl(organizationUrl)"
              />
            </ha-menu-list>
          </template>
        </HaDropdown>
      </div>
    </div>
  </section>
</template>

<script>
import {
  faArrowUpRightFromSquare,
  faEnvelope,
  faLink
} from '@fortawesome/pro-solid-svg-icons'
import {
  faFacebookF,
  faXTwitter,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import {
  HaButton,
  HaDropdown,
  HaMenuList,
  HaMenuListItem
} from '#components'
import useNotifications from '@ha/components-v3/src/composables/useNotifications'
import useConfigEnv from '@/composables/useConfigEnv'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'Share',
  components: {
    HaButton,
    HaDropdown,
    HaMenuList,
    HaMenuListItem
  },
  setup() {
    const { pushNotification } = useNotifications()
    const configEnv = useConfigEnv()
    const { editMode } = useEditMode()
    const organizationStore = useOrganizationStore()

    return {
      pushNotification,
      configEnv,
      editMode,
      organizationStore
    }
  },
  data() {
    return {
      hasHighlight: true,
      isNativeShareAvailable: false,
      faArrowUpRightFromSquare,
      faEnvelope,
      faLink
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    organizationUrl() {
      const slug = this.organization?.organizationSlug
      return `${this.configEnv.NUXT_ENV_BASE_URL}/associations/${slug}`
    },
    items() {
      return [
        {
          name: 'facebook',
          icon: faFacebookF,
          label: this.$t(
            'organization.share.networks.facebook.label'
          ),
          href: `https://www.facebook.com/sharer/sharer.php?u=${this.organizationUrl}`,
          dataUx:
            'Explore_OrganizationPublicPage_Share_ShareOnFacebook'
        },
        {
          name: 'twitter',
          icon: faXTwitter,
          label: this.$t('organization.share.networks.twitter.label'),
          href: `https://twitter.com/intent/tweet?text=${this.$t(
            'organization.share.networks.twitter.text',
            [this.organization?.name, this.organizationUrl]
          )}`,
          dataUx:
            'Explore_OrganizationPublicPage_Share_ShareOnTwitter'
        },
        {
          name: 'linkedin',
          icon: faLinkedin,
          label: this.$t(
            'organization.share.networks.linkedin.label'
          ),
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${this.organizationUrl}&title=${this.organization?.name}&source=HelloAsso`,
          dataUx:
            'Explore_OrganizationPublicPage_Share_ShareOnLinkedin'
        }
      ]
    }
  },
  beforeMount() {
    this.isNativeShareAvailable = 'canShare' in navigator
  },
  methods: {
    copyUrl(href) {
      navigator.clipboard.writeText(href)
      this.pushNotification({
        type: 'success',
        title: this.$t('toast.copyUrl.title'),
        body: this.$t('toast.copyUrl.body'),
        timeout: 5000
      })
    },
    async openSharePopup(href, openGenericSharing = false) {
      if (openGenericSharing) {
        await navigator.share({
          title: document.title,
          text: this.organizationStore.organization.description,
          url: window.location.href
        })
        return
      }

      const popupWidth = 560
      const popupHeight = 480
      const popupXPosition =
        window.screen.availLeft +
        (window.screen.availWidth - popupWidth) / 2
      const popupYPosition =
        window.screen.availTop +
        (window.screen.availHeight - popupHeight) / 2

      window.open(
        href,
        'Share',
        `width=${popupWidth},height=${popupHeight},left=${popupXPosition},top=${popupYPosition}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/share';
</style>
