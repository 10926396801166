<template>
  <ha-modal
    class="ModalNewAction"
    close-id="Explore_Modal_NewAction_Close"
    v-bind="$attrs"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('modal.newAction.title') }}
    </template>
    <template #body>
      <div class="Typologies">
        <ul class="TypologyList">
          <li
            v-for="typology in typologies"
            :key="typology.value"
            class="TypologyListItem"
          >
            <a
              :href="typology.url"
              class="TypologyListItem--Link"
              :style="`background-image: url(${`https://cdn.helloasso.com/images/explore/illustrations/typologies/typology-${typology.icon}.svg`})`"
              :data-ux="typology.tracking"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ typology.title }}
              <span class="visually-hidden">
                {{ $t('accessibility.openNewWindow') }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <ha-button
        data-ux="Explore_OrganizationPublicPage_EditMode_Modal_NewAction_Close"
        @click="$emit('close')"
      >
        {{ $t('button.close') }}
      </ha-button>
    </template>
  </ha-modal>
</template>

<script>
import { capitalize } from 'lodash-es'
import { enum as enums } from '@ha/helpers'
import { HaButton, HaModal } from '#components'
import { CampaignTypes } from '@/constants'
import useConfigEnv from '@/composables/useConfigEnv'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'ModalNewAction',
  components: {
    HaModal,
    HaButton
  },
  emits: ['close'],
  setup() {
    const configEnv = useConfigEnv()
    const organizationStore = useOrganizationStore()

    return { configEnv, organizationStore }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    formTypes() {
      // formTypes: helper with types by alphabetical order
      // CampaignTypes: constant with types in custom order
      return enums.FormType
    },
    typologies() {
      const result = []

      for (const type in CampaignTypes) {
        const lowerType = type.toLowerCase()
        result.push({
          value: lowerType,
          title: this.$t(
            `organization.actions.newAction.typologies.${lowerType}`
          ),
          url: this.newActionUrl(type),
          icon: `${lowerType}`,
          tracking: `Explore_OrganizationPublicPage_EditMode_Modal_NewAction_CreateNewAction_${capitalize(
            type
          )}`
        })
      }

      return result
    }
  },
  methods: {
    newActionUrl(type) {
      switch (type) {
        case this.formTypes.EVENT:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/evenements/creation`
        case this.formTypes.SHOP:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/boutiques/creation`
        case this.formTypes.MEMBERSHIP:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/adhesions/creation`
        case this.formTypes.CROWDFUNDING:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/collectes/creation `
        case this.formTypes.DONATION:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/formulaires/creation `
        case this.formTypes.PAYMENTFORM:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/ventes/creation`
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/modal-new-action';
</style>

<style lang="scss">
.ModalNewAction {
  > .HaModal--Wrapper {
    @include mediaQuery(600) {
      width: $ha-unit * 75;
    }
  }
}
</style>
