<template>
  <div
    class="Navigation"
    :aria-label="$t('organization.navigation.ariaLabel')"
  >
    <div
      :class="[
        'Navigation--Wrapper',
        { 'Is-Fixed': fixedNavigation }
      ]"
    >
      <ha-button
        class="Navigation--Scroller Navigation--Scroller-Left"
        variant="flat"
        color="basic"
        size="large"
        @click="scrollLeft()"
      >
        <template #icon>
          <HaIcon :icon="faChevronLeft" />
        </template>
      </ha-button>
      <nav :id="navId">
        <ul class="NavList">
          <li
            v-for="(href, name) in links"
            :key="name"
          >
            <a
              :href="href"
              :class="[
                'NavList--Item',
                { 'NavList--Item-Active': activeSection == name }
              ]"
              :data-ux="`Explore_OrganizationPublicPage_Navigation_${capitalize(name)}`"
              :data-test-id="`Explore_OrganizationPublicPage_Navigation_${capitalize(name)}`"
              @click="handleClick($event)"
            >
              {{ $t(`organization.navigation.${name}`) }}
            </a>
          </li>
        </ul>
      </nav>
      <ha-button
        class="Navigation--Scroller Navigation--Scroller-Right"
        variant="flat"
        color="basic"
        size="large"
        @click="scrollRight()"
      >
        <template #icon>
          <HaIcon :icon="faChevronRight" />
        </template>
      </ha-button>
    </div>
    <div class="Navigation--Placeholder" />
  </div>
</template>

<script>
import {
  filter,
  forEach,
  get,
  groupBy,
  size,
  uniqueId
} from 'lodash-es'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons'
import { HaButton, HaIcon } from '#components'
import { CampaignTypes } from '@/constants'
import { functions } from '@ha/helpers'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '~/store/organization.store'
import { useFormsStore } from '@/store/forms.store'

export default {
  name: 'Navigation',
  components: {
    HaButton,
    HaIcon
  },
  setup() {
    const { editMode } = useEditMode()
    const organizationStore = useOrganizationStore()
    const formsStore = useFormsStore()

    return { editMode, organizationStore, formsStore }
  },
  data() {
    return {
      hasScrolled: false,
      fixedNavigation: false,
      activeSection: null,
      faChevronLeft,
      faChevronRight
    }
  },
  computed: {
    forms() {
      return this.formsStore.forms
    },
    formTypes() {
      const groups = groupBy(this.forms, 'formType')

      const sortedGroups = {}

      // we set a new object with the correct order set in the constants
      for (const type in CampaignTypes) {
        // shop have no end date, skip the next part
        if (type === 'shop' && groups.Shop) {
          sortedGroups.Shop = groups.Shop
          return
        }

        if (groups[type]) {
          // we filter the ended forms
          const notEndedCampaigns = filter(groups[type], (form) => {
            return !this.isEnded(form)
          })

          if (notEndedCampaigns.length > 0) {
            sortedGroups[type] = notEndedCampaigns
          }
        }
      }

      return sortedGroups
    },
    organization() {
      return this.organizationStore.organization
    },
    formsNumber() {
      return size(
        filter(this.forms, (form) => {
          return !this.isEnded(form)
        })
      )
    },
    links() {
      const links = {}

      if (this.formsNumber === 0) {
        links.actions = '#actions'
      }

      if (this.forms.length > 1) {
        forEach(this.formTypes, (_, name) => {
          links[name.toLowerCase()] = `#${name}`.toLowerCase()
        })
      }

      if (this.organization.longDescription) {
        links.about = '#about'
      }

      if (
        this.organization.displayCoordinates ||
        this.hasSocials ||
        this.editMode
      ) {
        links.contact = '#contact'
      }
      return links
    },
    hasSocials() {
      return (
        this.organization.webSite ||
        this.organization.facebookPage ||
        this.organization.twitterPage ||
        this.organization.instagramPage ||
        this.organization.youtubePage
      )
    },
    navId() {
      return uniqueId('Nav-')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const nav = document.querySelector('.Navigation')
      const navWrapper = document.querySelector(
        '.Navigation--Wrapper'
      )
      const navPlaceholder = document.querySelector(
        '.Navigation--Placeholder'
      )
      const navOffset = nav.getBoundingClientRect()

      // Make navbar fixed
      if (navOffset.top <= 0) {
        this.fixedNavigation = true
        navPlaceholder.setAttribute(
          'style',
          `height: ${navWrapper.offsetHeight}px`
        )
      } else {
        this.fixedNavigation = false
        navPlaceholder.setAttribute('style', 'height: 0')
      }

      // Setup dynamic active links when crossing sections
      const navLinks = document.querySelectorAll('.Navigation a')
      const currentScroll = window.scrollY
      const currentScrollWithHeader = currentScroll + nav.offsetHeight

      this.activeSection = null

      navLinks.forEach((link) => {
        const section = document.querySelector(link.hash)
        const position = this.getPosition(section)
        const topOfSection = position.y - navOffset.height
        const BottomOfSection =
          topOfSection +
          get(section, 'offsetHeight', 0) +
          navOffset.height

        if (
          currentScrollWithHeader > topOfSection &&
          currentScrollWithHeader < BottomOfSection
        ) {
          this.activeSection = link.hash.replace('#', '')
        }
      })
    },
    handleClick(e) {
      e.preventDefault()

      const nav = document.querySelector('.Navigation')
      const element = document.querySelector(e.target.hash)
      const position = this.getPosition(element)
      const elementOffset = position.y - nav.offsetHeight * 2 + 1

      window.scrollTo({
        top: elementOffset,
        behavior: 'smooth'
      })
    },
    scrollLeft() {
      if (import.meta.client) {
        document
          .querySelector(`#${this.navId}`)
          .scrollBy({ left: -300, top: 0, behavior: 'smooth' })
      }
    },
    scrollRight() {
      if (import.meta.client) {
        document
          .querySelector(`#${this.navId}`)
          .scrollBy({ left: 300, top: 0, behavior: 'smooth' })
      }
    },
    isEnded(form) {
      const endDate = get(form, 'endDate')
      const startDate = get(form, 'startDate')

      if (endDate) {
        return new Date(endDate).getTime() < Date.now()
      }
      if (startDate) {
        return new Date(startDate).getTime() < Date.now()
      }
      return false
    },
    getPosition(el) {
      let xPos = 0
      let yPos = 0
      while (el) {
        xPos += el.offsetLeft - el.scrollLeft + el.clientLeft
        yPos += el.offsetTop - el.scrollTop + el.clientTop

        el = el.offsetParent
      }
      return {
        x: xPos,
        y: yPos
      }
    },
    capitalize(value) {
      return functions.capitalizeFirstLetter(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.Navigation {
  position: relative;
  margin-top: $ha-spacing-giant;
  margin-bottom: $ha-spacing-giant;

  @include mediaQuery(600) {
    margin-top: $ha-unit * 12; // 96px
    margin-bottom: $ha-unit * 12; // 96px
  }

  &--Wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    background-color: var(--ha-color-background);

    @include mediaQuery(900) {
      padding-right: $ha-unit * 5.5; // 44px
      padding-left: $ha-unit * 5.5; // 44px
    }

    @include mediaQuery(1200) {
      padding-right: 0;
      padding-left: 0;
    }

    &::before {
      left: 0;

      @include mediaQuery(900) {
        left: $ha-unit * 5.5; // 44px
      }
    }

    &::after {
      right: 0;
      transform: rotate(180deg);

      @include mediaQuery(900) {
        right: $ha-unit * 5.5; // 44px
      }
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: $ha-spacing-large;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgb(248 250 253 / 100%) 0%,
        rgb(255 255 255 / 0%) 100%
      );
      content: '';
      pointer-events: none;
      touch-action: none;

      @include mediaQuery(1200) {
        display: none;
      }
    }

    &.Is-Fixed {
      position: fixed;
      top: var(--header-height);
      left: 0;
      z-index: 3;
      box-shadow: $ha-box-shadow-middle;
      transform: translateZ(0);
    }
  }

  nav {
    flex: 1 1 100%;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--Scroller {
    position: absolute;
    top: 0;
    z-index: 2; // above links
    display: initial;
    height: 100%;
    border: none !important;
    background-image: linear-gradient(
      to var(--direction, right),
      var(--hads-color-air) 5%,
      transparent
    ) !important;

    &-Left {
      left: 0;
    }

    &-Right {
      right: 0;

      --direction: left;
    }

    @include mediaQuery(900) {
      display: none;
    }
  }

  .NavList {
    display: flex;
    width: 100%;
    justify-content: center;

    &--Item {
      position: relative;
      display: block;
      padding: $ha-spacing-large $ha-spacing-small;
      color: var(--ha-color-text-light);
      font-weight: $ha-font-weight-semibold;
      font-size: $ha-font-size-small;

      @include transition-base((color, background-color));

      @include mediaQuery(600) {
        padding: $ha-spacing-big $ha-spacing-large;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        background-color: set-lightness('background', 0.975);
        outline: none;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $ha-unit * 0.375; // 3px
        background-color: var(--ha-color-primary);
        border-radius: $ha-radius-large;
        transform: scaleX(0);
        transform-origin: bottom right;
        content: '';

        @include transition-base((transform));
      }

      &-Active,
      &:hover,
      &:focus {
        color: var(--ha-color-text);

        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
}
</style>
