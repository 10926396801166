<template>
  <div class="Banner">
    <template v-if="optimizedBannerUrl">
      <NuxtImg
        class="banner-back"
        :src="optimizedBannerUrl"
        role="presentation"
      />
      <NuxtImg
        class="banner-front"
        :src="optimizedBannerUrl"
        :alt="`Bannière de l'association ${organization.name}`"
      />
    </template>
    <img
      v-else
      class="banner-fallback"
      src="https://cdn.helloasso.com/images/explore/illustrations/patterns/pattern-shapes.svg"
      role="presentation"
    />
    <template v-if="editMode">
      <div class="Banner--Action">
        <ha-button
          class="Banner--ActionUpload"
          color="basic"
          variant="outline"
          data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Banner_Add"
          @click="displayModalUpload = true"
        >
          <template #icon>
            <HaIcon :icon="faCamera" />
          </template>
          {{ $t('organization.headline.banner.update') }}
          <span class="visually-hidden">
            {{ $t('organization.headline.banner.updateLabel') }}
          </span>
        </ha-button>
        <button
          v-if="banner"
          class="Banner--ActionDelete"
          data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Banner_Delete"
          @click="deleteBanner"
        >
          <HaIcon :icon="faTrash" />
          <span class="visually-hidden">
            {{ $t('organization.headline.banner.delete') }}
          </span>
        </button>
      </div>
    </template>
    <ModalUpload
      v-if="displayModalUpload"
      :media="banner"
      :ratio="3.83"
      :max-width="920"
      :max-height="240"
      :action="'updateBanner'"
      file-name="banner"
      @close="displayModalUpload = false"
    />
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { faCamera, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { HaButton, HaIcon } from '#components'
import useTracking from '@ha/components-v3/src/composables/useTracking'
import useNotifications from '@ha/components-v3/src/composables/useNotifications'

import ModalUpload from '@/components/Modal/ModalUpload.vue'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '@/store/organization.store'
import { useImage } from '#imports'

export default {
  name: 'Banner',
  components: {
    HaButton,
    HaIcon,
    ModalUpload
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { tracking } = useTracking()
    const { editMode } = useEditMode()
    const organizationStore = useOrganizationStore()
    const img = useImage()

    return {
      pushNotification,
      tracking,
      editMode,
      organizationStore,
      img
    }
  },
  data() {
    return {
      displayModalUpload: false,
      faCamera,
      faTrash
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    banner() {
      return this.organization?.banner
    },
    optimizedBannerUrl() {
      return this.img(this.banner, {
        format: 'webp',
        quality: 80,
        width: 920,
        height: 240
      })
    }
  },
  methods: {
    async deleteBanner() {
      try {
        await this.organizationStore.deleteBanner(
          this.organization.organizationSlug
        )
        this.tracking.track('Organization Page Updated', {
          organization_slug: this.organization.organizationSlug,
          update_type: ['Retrait Bannière']
        })
        this.pushNotification({
          type: 'success',
          title: this.$t('toast.bannerDelete.title'),
          body: this.$t('toast.bannerDelete.body'),
          timeout: 5000
        })
      } catch (error) {
        this.pushNotification({
          type: 'danger',
          title: this.$t(
            `error.code.${get(error, 'response.status', 500)}.title`
          ),
          body: this.$t(
            `error.code.${get(error, 'response.status', 500)}.message`
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Banner {
  position: relative;

  .banner-back,
  .banner-front,
  .banner-fallback {
    width: 100%;
    min-height: 160px;
    object-fit: cover;

    @include mediaQuery(600) {
      max-height: 240px;
    }
  }

  .banner-back {
    position: absolute;
  }

  .banner-front {
    position: relative;
    backdrop-filter: blur(8px) contrast(0.5) brightness(1.5);

    @include mediaQuery(600) {
      object-fit: contain;
    }
  }

  .banner-fallback {
    background: linear-gradient(
      130deg,
      rgb(96 24 220 / 100%) 0%,
      rgb(115 110 254 / 100%) 50%,
      rgb(67 203 255 / 100%) 100%
    );
  }

  &--Action {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: end;
    padding: $ha-spacing-small;
    left: 50%;
    translate: -50%;
    width: min(100%, 920px);

    @include mediaQuery(600) {
      bottom: 0;
    }

    &Delete {
      min-width: $ha-unit * 5.25; // 42px
      min-height: $ha-unit * 5.25; // 42px
      margin-left: $ha-spacing-small;
      padding: $ha-unit ($ha-unit * 1.25); // 8px 10px
      color: var(--ha-color-white);
      background-color: var(--ha-color-danger);
      border: $ha-border-width-regular solid transparent;
      border-radius: $ha-radius-regular;
      outline: none;
      cursor: pointer;

      @include transition-base(
        (color, background-color, border-color, box-shadow)
      );

      &:focus {
        box-shadow: 0 0 0 ($ha-unit * 0.5) set-alpha('danger', 0.25); // 0 0 0 4px
      }

      &:active {
        background: set-lightness('danger', 0.75);
      }

      &:hover,
      &:focus {
        background-color: set-lightness('danger', 0.875);
      }
    }
  }
}
</style>
