<template>
  <section
    id="actions"
    class="Organization--Actions"
  >
    <div
      :class="[
        'SectionHeader',
        { 'SectionHeader-Large': !editMode && formsNumber }
      ]"
    >
      <div class="Container">
        <span class="SectionHeader--Icon">
          <HaIcon :icon="faPaperPlane" />
        </span>
        <h2>
          <span>{{ $t('organization.actions.title_01') }}</span>
          {{ $t('organization.actions.title_02') }}
        </h2>
      </div>
    </div>
    <!-- No actions -->
    <template v-if="!formsNumber">
      <div class="NoActions">
        <div class="Container">
          <template v-if="editMode">
            <p>
              {{
                $t(
                  'organization.actions.noActions.titleFirstAction_01'
                )
              }}
              <br />
              <span>
                {{
                  $t(
                    'organization.actions.noActions.titleFirstAction_02'
                  )
                }}
              </span>
            </p>
            <div class="Typologies">
              <ul class="TypologyList">
                <li
                  v-for="typology in typologies"
                  :key="typology.value"
                  class="TypologyListItem"
                >
                  <a
                    :href="typology.url"
                    class="TypologyListItem--Link"
                    :style="`background-image: url(${`https://cdn.helloasso.com/images/explore/illustrations/typologies/typology-${typology.icon}`})`"
                    :data-ux="typology.tracking"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {{ typology.title }}
                    <span class="visually-hidden">
                      {{ $t('accessibility.openNewWindow') }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            <NuxtImg
              src="https://cdn.helloasso.com/images/explore/illustrations/illustration-desert.svg"
              alt=""
            />
            <p>
              {{ $t('organization.actions.noActions.title_01') }}
              <span>{{
                $t('organization.actions.noActions.title_02')
              }}</span>
            </p>
          </template>
        </div>
      </div>
    </template>
    <!-- Has actions -->
    <template v-else>
      <!-- New action -->
      <div
        v-if="editMode"
        class="NewAction"
      >
        <div class="Container">
          <p>{{ $t('organization.actions.newAction.title') }}</p>
          <ha-button
            data-ux="Explore_OrganizationPublicPage_EditMode_Actions_AddNewAction"
            @click="displayModalNewAction = true"
          >
            <template #icon>
              <HaIcon :icon="faPlus" />
            </template>
            {{ $t('organization.actions.newAction.cta') }}
          </ha-button>
        </div>
      </div>
      <!-- Highlight -->
      <Highlight color="var(--hads-color-desert-opacity-50)" />
      <!-- Has more than 1 action -->
      <div
        v-if="formsNumber"
        class="Actions"
      >
        <div class="Container">
          <div
            v-for="(group, formGroup) in formGroups"
            :id="`${formGroup.toLowerCase()}`"
            :key="formGroup.title"
            :data-test-id="`${formGroup}Section`"
            class="ActionGroup"
          >
            <p class="h4">
              {{
                $t(
                  `organization.actions.group.${formGroup.toLowerCase()}.title`
                )
              }}
              <span class="Badge">
                {{ group.length }}
              </span>
            </p>
            <ul class="ActionList">
              <Action
                v-for="(form, index) in group.slice(
                  0,
                  shownActions[formGroup] ? group.length : actionLimit
                )"
                :key="`${formGroup}_${index}`"
                :index="index"
                :form="form"
              />
            </ul>
            <ha-button
              v-if="actionLimit < group.length"
              variant="link"
              color="basic"
              icon-side="right"
              :data-ux="`Explore_OrganizationPublicPage_Actions_Action${formGroup}_ShowAllActions`"
              @click="showAllActions(formGroup)"
            >
              {{
                $t(
                  `organization.actions.group.${formGroup.toLowerCase()}.showAll`,
                  !shownActions[formGroup] || 2
                )
              }}
              <template #icon>
                <HaIcon :icon="faChevronRight" />
              </template>
            </ha-button>
          </div>
        </div>
      </div>
    </template>
    <client-only>
      <ModalNewAction
        v-if="displayModalNewAction"
        @close="displayModalNewAction = false"
      />
    </client-only>
  </section>
</template>

<script>
import {
  faPaperPlane,
  faPlus,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons'
import { enum as enums, functions } from '@ha/helpers'
import { HaButton, HaIcon } from '#components'
import { CampaignTypes } from '@/constants'
import useConfigEnv from '@/composables/useConfigEnv'
import useEditMode from '@/composables/useEditMode'
import Highlight from '@/components/Organization/Partials/Highlight.vue'
import Action from '@/components/Organization/Partials/Action.vue'
import ModalNewAction from '@/components/Modal/ModalNewAction.vue'
import { useOrganizationStore } from '@/store/organization.store'
import { useFormsStore } from '@/store/forms.store'

export default {
  name: 'Actions',
  components: {
    HaButton,
    Highlight,
    Action,
    ModalNewAction,
    HaIcon
  },
  setup() {
    const configEnv = useConfigEnv()
    const { editMode } = useEditMode()
    const organizationStore = useOrganizationStore()
    const formsStore = useFormsStore()

    return { configEnv, editMode, organizationStore, formsStore }
  },
  data() {
    return {
      actionLimit: 4,
      shownActions: [],
      displayModalNewAction: false,
      faPaperPlane,
      faPlus,
      faChevronRight
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    formTypes() {
      // formTypes: helper with types by alphabetical order
      // CampaignTypes: constant with types in custom order
      return enums.FormType
    },
    formGroups() {
      const sortedGroups = {}

      // we set a new object with the correct order set in the constants
      for (const type in CampaignTypes) {
        const formsByType = this.formsStore.forms?.filter((form) => {
          return form.formType === type && !this.isEnded(form)
        })
        if (formsByType.length) sortedGroups[type] = formsByType
      }

      return sortedGroups
    },
    formsNumber() {
      return this.formsStore.forms?.filter((form) => {
        return !this.isEnded(form)
      }).length
    },
    typologies() {
      const result = []

      for (const type in CampaignTypes) {
        const lowerType = type.toLowerCase()
        result.push({
          value: lowerType,
          title: this.$t(
            `organization.actions.newAction.typologies.${lowerType}`
          ),
          url: this.newActionUrl(type),
          icon: `${lowerType}.svg`,
          tracking: `Explore_OrganizationPublicPage_EditMode_Actions_CreateNewAction_${functions.capitalizeFirstLetter(
            type
          )}`
        })
      }

      return result
    }
  },
  methods: {
    newActionUrl(type) {
      switch (type) {
        case this.formTypes.EVENT:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/evenements/creation`
        case this.formTypes.SHOP:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/boutiques/creation`
        case this.formTypes.MEMBERSHIP:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/adhesions/creation`
        case this.formTypes.CROWDFUNDING:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/collectes/creation `
        case this.formTypes.DONATION:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/formulaires/creation `
        case this.formTypes.PAYMENTFORM:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/ventes/creation`
        default:
          return ''
      }
    },
    showAllActions(formType) {
      const actionsByType = []
      actionsByType[formType] = !this.shownActions[formType]
      this.shownActions = actionsByType
    },
    isEnded(form) {
      const endDate = form.endDate
      const startDate = form.startDate

      if (endDate) {
        return new Date(endDate).getTime() < Date.now()
      }
      if (startDate) {
        return new Date(startDate).getTime() < Date.now()
      }
      return false
    }
  }
}
</script>

<style lang="scss">
@import './styles/actions';
</style>
