<template>
  <figure class="Logo">
    <div class="LogoWrapper">
      <NuxtImg
        v-if="logo && !hasLogoError"
        :src="logo"
        :width="140"
        :height="140"
        format="webp"
        quality="80"
        class="Logo--Image"
        data-test-id="LogoImage"
        :alt="`Logo de l'association ${organization.name}`"
        @error="setLogoError"
      />
      <span v-else class="Logo--Empty">
          <HaIcon :icon="faUsers" />
      </span>

      <template v-if="editMode">
        <button
          class="Logo--Upload"
          data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Logo_Add"
          @click="displayModalUpload = true"
        >
          <HaIcon :icon="faCamera" />
          <span class="visually-hidden">
            {{ $t('organization.headline.logo.upload') }}
          </span>
        </button>
        <ha-button
          v-if="logo"
          data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Logo_Delete"
          class="Logo--Delete"
          color="danger"
          @click="deleteLogo"
        >
          <HaIcon :icon="faTrash" />
          <span class="visually-hidden">
            {{ $t('organization.headline.logo.delete') }}
          </span>
        </ha-button>
      </template>
    </div>
    <ModalUpload
      v-if="displayModalUpload"
      :media="logo"
      :max-width="140"
      :max-height="140"
      :action="'updateLogo'"
      file-name="logo"
      @close="displayModalUpload = false"
    />
  </figure>
</template>

<script>
import { get } from 'lodash-es'
import {
  faUsers,
  faCamera,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'
import { HaButton, HaIcon } from '#components'
import useTracking from '@ha/components-v3/src/composables/useTracking'
import useNotifications from '@ha/components-v3/src/composables/useNotifications'
import ModalUpload from '@/components/Modal/ModalUpload.vue'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'Logo',
  components: {
    HaButton,
    ModalUpload,
    HaIcon
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { tracking } = useTracking()
    const { editMode } = useEditMode()
    const organizationStore = useOrganizationStore()

    return { pushNotification, tracking, editMode, organizationStore }
  },

  data() {
    return {
      displayModalUpload: false,
      hasLogoLoaded: false,
      hasLogoError: false,
      faUsers,
      faCamera,
      faTrash
    }
  },
  computed: {
    isLogoDisplayed() {
      return this.logo && this.hasLogoLoaded && !this.hasLogoError
    },
    organization() {
      return this.organizationStore.organization
    },
    logo () {
      return this.organization?.logo
    }
  },
  methods: {
    setLogoLoad() {
      this.hasLogoLoaded = true
    },
    setLogoError() {
      this.hasLogoError = true
    },
    async deleteLogo() {
      try {
        await this.organizationStore.deleteLogo(
          this.organization.organizationSlug
        )
        this.tracking?.track('Organization Page Updated', {
          organization_slug: this.organization.organizationSlug,
          update_type: ['Retrait Logo']
        })
        this.pushNotification({
          type: 'success',
          title: this.$t('toast.logoDelete.title'),
          body: this.$t('toast.logoDelete.body'),
          timeout: 5000
        })
      } catch (error) {
        this.pushNotification({
          type: 'danger',
          title: this.$t(
            `error.code.${get(error, 'response.status', 500)}.title`
          ),
          body: this.$t(
            `error.code.${get(error, 'response.status', 500)}.message`
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Logo {
  &Wrapper {
    position: relative;
    z-index: 2; // Over Background
    display: flex;
    justify-content: center;
    width: $ha-unit * 12; // 96px
    height: $ha-unit * 12; // 96px
    background-color: var(--ha-color-background);
    border: $ha-unit * 0.875 solid var(--ha-color-white); // 7px
    border-radius: $ha-radius-round;
    box-shadow: $ha-box-shadow-middle;
    transform: translate3d(0, -$ha-unit * 6, 0); // -48px

    @include mediaQuery(600) {
      width: $ha-unit * 20; // 160px
      height: $ha-unit * 20; // 160px
      border-width: $ha-unit * 1.25; // 10px
      transform: translate3d(0, -$ha-unit * 10, 0); // -80px
    }
  }

  &--Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $ha-radius-round;
  }

  &--Empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--ha-color-text-light);
    font-size: $ha-unit * 4; // 32px

    @include mediaQuery(600) {
      font-size: $ha-unit * 7; // 56px
    }
  }

  &--Upload {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: var(--ha-color-white);
    font-size: $ha-font-size-jumbo;
    background-color: set-alpha(text, 0.75);
    border-radius: $ha-radius-round;
    transform: scale(0.9);
    opacity: 0;

    @include transition-base((all));

    @include mediaQuery(900) {
      font-size: $ha-font-size-giant;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      transform: scale(1);
      opacity: 1;
    }
  }

  &--Delete {
    position: absolute;
    top: -($ha-unit * 0.875); // -7px (border-width)
    right: -($ha-unit * 0.875); // -7px (border-width)
    z-index: 2;
    width: $ha-unit * 4; // 32px
    height: $ha-unit * 4; // 32px
    padding: 0;
    font-size: $ha-font-size-small;
    border-radius: $ha-radius-round;

    @include mediaQuery(600) {
      top: -($ha-unit * 1.25); // -10px
      right: -($ha-unit * 1.25); // -10px
      width: $ha-unit * 5; // 40px
      height: $ha-unit * 5; // 40px
      font-size: $ha-font-size-regular;
    }
  }
}
</style>
