<template>
  <section
    v-if="organization.longDescription || editMode"
    id="about"
    class="Organization--About"
  >
    <div class="Container">
      <div class="SectionHeader">
        <span class="SectionHeader--Icon">
          <HaIcon :icon="faUser" />
        </span>
        <h2>
          <span>{{ $t('organization.about.title_01') }}</span>
          {{ $t('organization.about.title_02') }}
        </h2>
      </div>
      <editor
        v-if="editMode"
        :initial-value="organization.longDescription"
        :upload-url="uploadUrl"
        :upload-headers="uploadHeaders"
        :on-save="save"
        :placeholder="
          $t('organization.about.longDescriptionPlaceholder')
        "
      />
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-else
        class="LongDescription"
        v-html="formattedDescription"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </section>
</template>

<script>
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import Editor from '@/components/Editor/Editor.vue'

import { HaIcon } from '#components'
import useNotifications from '@ha/components-v3/src/composables/useNotifications'
import useSegment from '@/composables/useSegment'
import useCookies from '@/composables/useCookies'
import useConfigEnv from '@/composables/useConfigEnv'
import useEditMode from '@/composables/useEditMode'
import { useI18n } from '#imports'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'About',
  components: {
    Editor,
    HaIcon
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { trackUpdateOrganization } = useSegment()
    const { getAccessToken } = useCookies()
    const configEnv = useConfigEnv()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    const organizationStore = useOrganizationStore()

    return {
      pushNotification,
      trackUpdateOrganization,
      getAccessToken,
      configEnv,
      editMode,
      i18n,
      faUser,
      organizationStore
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    uploadUrl() {
      return `${this.configEnv.NUXT_ENV_BASE_API}/organizations/${this.organization.organizationSlug}/image/rte`
    },
    uploadHeaders() {
      return {
        authorization: `Bearer ${this.getAccessToken()}`
      }
    },
    formattedDescription() {
      const description = this.organization.longDescription
      return description.replace(/h3>/g, 'h4>').replace(/h2>/g, 'h3>')
    }
  },
  methods: {
    save(value) {
      const payload = {
        address: this.organization.address,
        category: this.organization.category,
        city: this.organization.city,
        description: this.organization.descriptionModel,
        displayCoordinates: this.organization.displayCoordinates,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        instagramPage: this.organization.instagramPage,
        longDescription: value.replace(/<h[1-2]>.*<\/h[1-2]>/, ''),
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }
      this.trackUpdateOrganization(
        this.organization.organizationSlug,
        this.organization,
        payload
      )

      this.organizationStore
        .saveOrganizationData({
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.pushNotification({
            type: 'success',
            title: this.$t('toast.longDescriptionUpdate.title'),
            body: this.$t('toast.longDescriptionUpdate.body'),
            timeout: 5000
          })
        })
        .catch((error) => {
          this.pushNotification({
            type: 'danger',
            title: this.$t(
              `error.code.${error?.response.status}.title`
            ),
            body: this.$t(
              `error.code.${error?.response.status}.message`
            ),
            timeout: 5000
          })
          throw error
        })
    }
  }
}
</script>

<style lang="scss">
@import './styles/about';
</style>
